import { message } from "antd";
import { axiosClient } from "../../config/axiosConfig";
import { store } from "../store";
import { PRODUCT_ACTIONS } from "./productActionTypes";

export const updateProductFamily = (payload) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_FAMILY,
    payload: payload,
});
export const updateProductFamilySelected = (prodFamily) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_FAMILY_SELECTED,
    payload: prodFamily,
});
export const updateProducts = (products) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCTS,
    payload: products,
});
export const updateProductSelected = (selectedProduct) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_SELECTED,
    payload: selectedProduct,
});

export const updateWindingTypeSelected = (selectedType) => ({
    type: PRODUCT_ACTIONS.UPDATE_WINDING_TYPE_SELECTED,
    payload: selectedType,
});

export const updateWindingTypes = (windings) => ({
    type: PRODUCT_ACTIONS.UPDATE_WINDING_TYPE,
    payload: windings,
});

export const updateProductDetails = (details) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_DETAILS,
    payload: details,
});

export const updateEndUseCodes = (data) => ({
    type: PRODUCT_ACTIONS.UPDATE_END_USE_CODE,
    payload: data,
});
export const updateProductDetailsByWindings = (data) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_DETAILS_BY_WINDINGS,
    payload: data,
});
export const updateUnitsSelected = (units) => ({
    type: PRODUCT_ACTIONS.UPDATE_UNITS_SELECTED,
    payload: units,
});
export const updatePricingRuleProductFamilySelected = (family) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_FAMILY_SELECTED_PRICING_RULE,
    payload: family,
});
export const updatePricingRuleProductSelected = (product) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_SELECTED_PRICING_RULE,
    payload: product,
});

export const getWindingType =
    (products, productSelected, units = "english") =>
    async (dispatch) => {
        const product = products.filter((prod) => prod.PRODUCT_NAME === productSelected)[0];
        const response = await axiosClient.post("/api/get_windingtype/", {
            Film_Name: product?.FILM_NAME,
            Product_Name: product?.PRODUCT_NAME,
            Unit_Type: units,
        });

        if (response.length > 0) {
            const formattedWind = response.map((item) => ({
                label: item,
                value: item,
            }));
            dispatch(updateWindingTypes(formattedWind));
            return formattedWind;
        } else {
            return 0;
        }
    };

export const getWindings = async (product, units = "english") => {
    try {
        return await axiosClient.post("/api/get_windingtype/", {
            Film_Name: product?.FILM_NAME,
            Product_Name: product?.PRODUCT_NAME,
            Unit_Type: units,
        });
    } catch (err) {
        console.log(err.message);
    }
};
export const getProuducts = () => async (dispatch) => {
    await axiosClient
        .post("/api/get_productname/", {})
        .then((res) => {
            let formattedProducts = res
                .map((item) => ({
                    ...item,
                    label: item.PRODUCT_NAME,
                    value: item.PRODUCT_NAME,
                }))
                .sort((one, two) => parseInt(one.PRODUCT_NAME) - parseInt(two.PRODUCT_NAME));
            dispatch(updateProducts(formattedProducts));
        })
        .catch((err) => console.log(err));
};

export const getProductFamily = () => async (dispatch) => {
    await axiosClient
        .post("/api/get_productfamily/", {})
        .then((res) => dispatch(updateProductFamily(res)))
        .catch((err) => console.log(err));
};
export const getProuductDetails =
    (products, productSelected, windingTypeSelected, units = "english") =>
    async (dispatch) => {
        let product = products.filter((item) => item.PRODUCT_NAME === productSelected)[0];
        const key = "productDetails";
        message.loading({
            content: "Fetching product details...",
            key,
            duration: 200,
        });
        await axiosClient
            .post("/api/get_productdetails/", {
                Film_Name: product?.FILM_NAME,
                Product_Name: product?.PRODUCT_NAME,
                Winding_Type: windingTypeSelected,
                Trade_Name: product?.TRADE_NAME,
                Unit_Type: units,
            })
            .then((res) => {
                dispatch(updateProductDetails(res[0]));
                message.success({
                    content: "Product details fetched successfully.",
                    key,
                });
            })
            .catch((err) => {
                message.error({
                    content: err?.message || "Something went wrong",
                    key,
                });
                console.log(err);
            });
    };

export const getEndUseCode = () => async (dispatch) => {
    await axiosClient
        .get("/api/get_end_user_code/")
        .then((res) => {
            let formatted = res.map((item) => {
                return {
                    ...item,
                    value: `${item.EUC}-${item.EUC_DESCRIPTION}`,
                };
            });
            dispatch(updateEndUseCodes(formatted));
        })
        .catch((err) => console.log(err));
};

export const updateProductWeight = (data) => ({
    type: PRODUCT_ACTIONS.UPDATE_PRODUCT_WEIGHT,
    payload: data,
});

export const getProductWeight =
    (productSelected, units = "english") =>
    async (dispatch) => {
        const productDetails = store.getState().product.products.filter((prod) => prod.PRODUCT_NAME === productSelected)[0];
        await axiosClient
            .post("/api/get_productweight/", {
                Film_Name: productDetails?.FILM_NAME,
                Product_Name: productDetails?.PRODUCT_NAME,
                Unit_Type: units,
            })
            .then((res) => {
                dispatch(updateProductWeight(res[0]));
            })
            .catch((er) => console.log(er));
    };
export const updateOrderDetails = (data) => ({
    type: PRODUCT_ACTIONS.UPDATE_ORDER_DETAILS,
    payload: data,
});

export const checkStandard =
    (profileSelected, form, productSelected, messageApi, units = "english") =>
    async (dispatch) => {
        let endpoint = "";
        if (profileSelected === "lengthRoll") endpoint = "/api/check_standard_profileLengthRoll/";
        else if (profileSelected === "lengthWeight") endpoint = "/api/check_standard_profileLengthWeight/";
        else if (profileSelected === "odRoll") endpoint = "/api/check_standard_profileODRoll/";
        else if (profileSelected === "odWeight") endpoint = "/api/check_standard_profileODWeight/";
        else {
            alert("not implemented yet");
            return;
        }
        const filmName = store.getState().product.products.filter((prod) => prod.PRODUCT_NAME === productSelected)[0].FILM_NAME;

        const tradeName = store.getState().product.products.filter((prod) => prod.PRODUCT_NAME === productSelected)[0].TRADE_NAME;
        let formValues = form.getFieldsValue();
        messageApi.open({
            key: "checking_standard",
            type: "loading",
            content: "Checking Standard, Please wait...",
            duration: 20,
        });
        await axiosClient
            .post(endpoint, {
                Film_Name: filmName,
                Trade_Name: tradeName,
                Product_Name: productSelected,
                Winding_Type: formValues.windingType,
                Roll_Length: formValues.rollLength,
                Roll_Width: formValues.rollWidth,
                No_Of_Rolls: formValues.numberOfRolls || formValues.numberOfRollsbyWt,
                Total_Length: formValues.totalLength,
                Roll_Weight: formValues.rollWeight,
                Total_Weight: formValues.totalWeight,
                Core_Diameter: formValues.coreDiameter,
                Core_OD: formValues.coreOutsideDiameter,
                Roll_OD: formValues.filmrollOutsideDiameter,
                End_User_Code: formValues?.endUseCode?.split("-")[0],
                Unit_Type: units,
            })
            .then((res) => {
                if (res.error || res.error === "") {
                    messageApi.open({
                        key: "checking_standard",
                        type: "error",
                        content: res.error,
                        duration: 5,
                    });
                }
                messageApi.open({
                    key: "checking_standard",
                    type: res.status === "Standard" ? "success" : "error",
                    content: `The order is ${res.status}`,
                    duration: 5,
                });

                dispatch(
                    updateOrderDetails({
                        windingType: formValues.windingType,
                        rollWidth: formValues.rollWidth,
                        rollLength: formValues.rollLength,
                        numberOfRolls: formValues.numberOfRolls,
                        totalLength: formValues.totalLength,
                        rollWeight: formValues.rollWeight,
                        totalWeight: formValues.totalWeight,
                        coreDiameter: formValues.coreDiameter,
                        coreOutsideDiameter: formValues.coreOutsideDiameter,
                        filmrollOutsideDiameter: formValues.filmrollOutsideDiameter,
                        endUseCode: formValues.endUseCode,
                        customerSpecificationCode: formValues.customerSpecificationCode,
                        result: res,
                    })
                );
            })
            .catch((err) => console.log(err));
    };

export const getProductFilmTypes = () => async (dispatch) => {
    try {
        const response = await axiosClient.get("/api/get_productfilmtype/");
        dispatch({
            type: PRODUCT_ACTIONS.SET_PRODUCT_FILM_TYPES,
            payload: response,
        });
    } catch (error) {}
};

export const getAllProductsByWinding = (windings, products, productSelected) => async (dispatch) => {
    let product = products.filter((item) => item.PRODUCT_NAME === productSelected)[0];
    let response = await Promise.all(
        windings.map(async (winding) => {
            try {
                const res = await axiosClient.post("/api/get_productdetails/", {
                    Film_Name: product?.FILM_NAME,
                    Product_Name: product?.PRODUCT_NAME,
                    Winding_Type: winding?.value || winding,
                    Trade_Name: product?.TRADE_NAME,
                    // Unit_Type: units,
                });
                return res[0];
            } catch (err) {}
        })
    );
    dispatch(updateProductDetailsByWindings(response));
};
export const updateProduct = async (productDetails, url = "/api/update_product/") => {
    try {
        return await axiosClient.post(url, productDetails);
    } catch (err) {
        throw err;
    }
};
export const updateProductComment = async (productDetails, comment) => {
    try {
        return await axiosClient.post("/api/update_comment/", {
            Product_Name: productDetails.PRODUCT_NAME,
            Comment: comment,
        });
    } catch (err) {
        throw err;
    }
};

export const addNewProduct = async (productDetails, url = "/api/add_product/") => {
    try {
        return await axiosClient.post(url, productDetails);
    } catch (err) {
        throw err;
    }
};
